<template>
  <header>
    <v-container>
      <div class="d-flex justify-space-between">
        <v-btn class="d-block d-md-none" density="comfortable" icon="mdi-menu" @click="mobileMenu = true"></v-btn>
        <div class="d-none d-md-block">
          <router-link :to="{name:'Home'}" v-slot="{ href, navigate }" custom>
            <v-btn :href="href" prepend-icon="$vuetify" @click="navigate">
              Главная
            </v-btn>
          </router-link>
        </div>
        <div class="d-none d-md-flex mx-3">
          <router-link class="mr-1" :to="{name:'Products'}" v-slot="{ isActive, href, navigate }" custom>
            <v-btn :href="href" @click="navigate" :active="isActive">
              Товары
            </v-btn>
          </router-link>
          <router-link class="mx-1" :to="{name:'Packages'}" v-slot="{ isActive, href, navigate }" custom>
            <v-btn :href="href" @click="navigate" :active="isActive">
              Пакеты
            </v-btn>
          </router-link>
          <router-link class="ml-1" :to="{name:'Education'}" v-slot="{ isActive, href, navigate }" custom>
            <v-btn :href="href" @click="navigate" :active="isActive">
              Обучение
            </v-btn>
          </router-link>
        </div>
        <div class="d-flex">
          <router-link class="mr-1" :to="{name:'FAQ'}" v-slot="{ isActive, href, navigate }" custom>
            <v-btn :href="href" prepend-icon="mdi-comment-question-outline" @click="navigate" :active="isActive">
              <span class="d-none d-sm-inline">FAQ</span>
            </v-btn>
          </router-link>
          <v-btn class="mx-1" prepend-icon="mdi-account-plus-outline">
            <span class="d-none d-sm-inline">Регистрация</span>
          </v-btn>
          <v-btn class="mx-1" prepend-icon="mdi-login">
            <span class="d-none d-sm-inline">Войти</span>
          </v-btn>
          <v-btn class="ml-1" density="comfortable" icon="mdi-lightbulb-on" @click="toggleTheme"></v-btn>
        </div>
      </div>
    </v-container>
    <v-dialog
        v-model="mobileMenu"
        class="align-center justify-center"
        opacity="0.9"
    >
      <v-container>
        <router-link block class="mb-3" :to="{name:'Home'}" v-slot="{ isActive, href, navigate }" custom>
          <v-btn :href="href" prepend-icon="$vuetify" @click="navigate" :active="isActive">
            Главная
          </v-btn>
        </router-link>
        <router-link block class="mb-3" :to="{name:'Products'}" v-slot="{ isActive, href, navigate }" custom>
          <v-btn :href="href" @click="navigate" :active="isActive">
            Товары
          </v-btn>
        </router-link>
        <router-link block class="mb-3" :to="{name:'Packages'}" v-slot="{ isActive, href, navigate }" custom>
          <v-btn :href="href" @click="navigate" :active="isActive">
            Пакеты
          </v-btn>
        </router-link>
        <router-link block :to="{name:'Education'}" v-slot="{ isActive, href, navigate }" custom>
          <v-btn :href="href" @click="navigate" :active="isActive">
            Обучение
          </v-btn>
        </router-link>
      </v-container>
    </v-dialog>
  </header>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
  setup() {
    const theme = useTheme()

    function toggleTheme() {
      theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }

    return {
      toggleTheme
    }
  },
  data() {
    return {
      mobileMenu: false
    }
  },
  watch: {
    '$route'() {
      this.mobileMenu = false
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(40px);
  }
</style>
