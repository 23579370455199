<template>
  <div class="pt-1">
    <div class="adv_block adv_block__top mb-2">
      <v-row>
        <v-col
            v-for="(item, index) in banners.top"
            :key="'top_' + index"
            cols="4"
        >
          <img :src="item['image']" :alt="item['alt_name']" />
        </v-col>
      </v-row>
    </div>
    <v-container class="pa-0">
      <div class="adv_block adv_block__top-small my-2">
        <v-row>
          <v-col
              v-for="(item, index) in banners.topsmall"
              :key="'top_' + index"
              cols="4"
          >
            <img :src="item['image']" :alt="item['alt_name']" />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div>
      <div class="d-flex my-2 flex-column flex-md-row">
        <div class="adv_block adv_block__side flex-grow-1">
          <v-row>
            <v-col
                v-for="(item, index) in banners.left"
                :key="'top_' + index"
                cols="4"
                md="12"
            >
              <img :src="item['image']" :alt="item['alt_name']" />
            </v-col>
          </v-row>
        </div>
        <v-container class="flex-shrink-0">
          <slot></slot>
        </v-container>
        <div class="adv_block adv_block__side flex-grow-1">
          <v-row>
            <v-col
                v-for="(item, index) in banners.right"
                :key="'top_' + index"
                cols="4"
                md="12"
            >
              <img :src="item['image']" :alt="item['alt_name']" />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="adv_block adv_block__bottom">
      <v-row>
        <v-col
            v-for="(item, index) in banners.bottom"
            :key="'top_' + index"
            cols="4"
        >
          <img :src="item['image']" :alt="item['alt_name']" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import getPathRequest from '@/assets/helpers/get_path_request'

export default {
  name: 'AdvertisingBase',
  data() {
    return {
      banners: {
        top: [],
        topsmall: [],
        left: [],
        right: [],
        bottom: [],
        modal: []
      }
    }
  },
  created() {
    const places = ["TOP_1_1", "TOP_1_2", "TOP_1_3", "TOP_2_1", "TOP_2_2", "TOP_2_3", "TOP_SMALL_1", "TOP_SMALL_2", "TOP_SMALL_3", "TOP_SMALL_4", "TOP_SMALL_5", "TOP_SMALL_6", "LEFT_1", "LEFT_2", "LEFT_3", "LEFT_4", "LEFT_5", "RIGHT_1", "RIGHT_2", "RIGHT_3", "RIGHT_4", "RIGHT_5", "BOTTOM_1", "BOTTOM_2", "BOTTOM_3", "MODAL"]
    places.forEach(item => {
      axios.get(getPathRequest('get_banner', {place: item}))
          .then(response => {
            const block = item.replace(/[^a-zA-Z]/g, '').toLowerCase()
            let temp_list = structuredClone(this.banners[block])
            temp_list.push(response.data)
            this.banners[block] = temp_list.sort((a, b) => {
              return a['place'].replace(/[^+\d]/g, '') - b['place'].replace(/[^+\d]/g, '')
            })
          })
          .catch(error => {
            console.log(error)
          })
    })
  }
}
</script>

<style scoped lang="scss">
.flex-grow-1 {
  flex-grow: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
</style>
